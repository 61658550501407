@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.modal {
    width: 450px;
    max-width: 100%;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border: 2px solid $black-20;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;

    .iconCheck {
        margin-bottom: 1.5rem;
        width: 40px;
        height: auto;
        color: $black-40;
    }

    h3 {
        margin-bottom: .5rem;
        color: $black-90;
        font-weight: $font-weight-bold;
    }

    p.subTitle {
        font-weight: $font-weight-medium;
        margin-bottom: 1.5rem;
        color: $black-90;
    }

    p.description {
        font-weight: $font-weight-medium;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        color: $black-50;
    }

    .closeModalButton {
        width: 100%;
    }

    @media screen and (max-width: $screen-md) {
        padding: 1.5rem;
        max-width: 92%;
    }

    &.printLabelModal {
        min-height: 650px;

        @media screen and (max-width: $screen-md) {
            min-height: auto;
        }

        h3 {
            margin-bottom: 1rem;
        }

        .pdfWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 75%;
            height: 440px;
            margin: auto;

            @media screen and (max-width: $screen-xs) {
                height: 380px;
            }

            canvas {
                width: 100% !important;
                height: auto !important;
            }
        }

        .downloadPdfButton {
            position: relative;
            z-index: 10;
            width: 100%;
            margin: 1rem 0;
        }

        .closeModalButton {
            position: relative;
        }
    }
}